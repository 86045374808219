import { Button } from "@mui/material";

interface PhoneButtonProps {
    phoneNumber: string | null;
}

const PhoneButton = ( {phoneNumber} : PhoneButtonProps  ) => {
  let formattedPhone = '';
  let phoneDisabled = false;
  if(phoneNumber === null || phoneNumber === '') {
    formattedPhone = "(XXX) XXX-XXXX";
    phoneDisabled = true;
  } else {
    const phoneStr = phoneNumber;
    formattedPhone = "(" + phoneStr.substring(0, 3) + ") " + phoneStr.substring(3, 6) + "-" + phoneStr.substring(6);
  }

  const handleClick = () => {
    // TODO figure out how to pass value for phone number into function
    // alert("Calling now.... RING RING!");
    window.open("tel:+1" + phoneNumber);
  }

  return (
    <>
      <Button
        size="small"
        onClick={handleClick}
        disabled={phoneDisabled}
      >
        {formattedPhone}
      </Button>
    </>
  )
}

export default PhoneButton;