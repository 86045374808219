import React, { useEffect } from 'react';
import PharmacyList from '../pharmacy/PharmacyList';
import { Pharmacies } from "../../types/Pharmacy";
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import PharmacyMap from '../pharmacy-map/PharmacyMap';
import { useState } from 'react';
import MedicationSearch from '../medication-search/MedicationSearch';
import { IPricingModeContext, PharmacyPricingModeContext, PricingData, useMedicationPricingMode } from '../../utils/pricing-mode/PricingModeContext';
import theme from '../../styles/materialTheme';
import { useAppData } from '../../App';
import { Plan } from '../../types/Plan';

interface PharmacySearchResultsProps {
  results: Pharmacies;
  selectedPlan: Plan;
}

const PharmacySearchResults = ( {results, selectedPlan} : PharmacySearchResultsProps ) => {
  const appData = useAppData();
  const [displayCount, setdisplayCount] = useState(10);
  const [selectedPharmacy, setSelectedPharmacy] = useState(-1);
  const [medicationPricingData, pricingPlan, medicationPricingMode, setMedicationPricingMode] = useMedicationPricingMode(); // TODO: overlapping pricing modes is a bit confuising; maybe need to create separate components for pharmacySearchResults that are displayed in the medicationSearch for pricing mode..
  const [pricingMode, setPricingMode] = useState(false);
  const pharmacy = results.find(p => p.id === selectedPharmacy);
  const pricingData: PricingData = {
    pharmacyId: pharmacy?.Nabp + "-" + pharmacy?.Npi, // Nabp + "-" + Npi
    pharmacyNpi: pharmacy?.Npi,
  }
  const contextValue: IPricingModeContext = [pricingData, selectedPlan, pricingMode, setPricingMode]; // TODO update undefined pricingData
  const [price, setPrice] = useState('');
  const [priceError, setPriceError] = useState(false);
  const [isPriceLoading, setIsPriceLoading] = useState(false);

  useEffect(() => { // request pricing logic (runs when pricing mode true and pharmacy is selected)
    if( medicationPricingMode && medicationPricingData != undefined && selectedPharmacy != -1 ) {
      const urlPrefix = process.env.REACT_APP_EXT_SVC_BASE_URL;
      setPrice('');
      setPriceError(false);
      setIsPriceLoading(true);
      const fetchPrice = async () => {
        const pricingInfoResp = await fetch(urlPrefix + '/GetMedAssistPricingInfo', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ApplicationToken: process.env.REACT_APP_APP_TOKEN,
            SubscriberNum: pricingPlan.SubscriberNum
          })
        });
        const pricingInfo = await pricingInfoResp.json();

        const pharmacy = results.find(p => p.id === selectedPharmacy);

        fetch(urlPrefix + '/LakerApi/GetPricingClaimVerbose', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify([{
            WebAccountLinkToken: process.env.REACT_APP_APP_TOKEN,
            RegionId: 23,               // does defaulting this to 23 work ??
            Physiciannpi: "1234567893", // This needs to be hard coded
            Override: false,            // hard code?
            Ndc: medicationPricingData.ndc,
            DrugType: medicationPricingData.drugType,
            Quantity: medicationPricingData.quantity,
            Dayssupply: medicationPricingData.daysSupply,
            Id: pharmacy?.Nabp + "-" + pharmacy?.Npi, // Nabp + "-" + Npi
            Pharmacynpi: pharmacy?.Npi,
            Subscribernum: pricingPlan?.SubscriberNum,
            Personcode: pricingInfo.PersonCode,
            Dob: pricingInfo.DOB, // TODO: check that I do not need to process the DOB at all
            LastName: pricingInfo.LastName,
            FirstName: pricingInfo.FirstName,
            Mi: pricingInfo.Mi,
            GenderCode: pricingInfo.Gendercode,
            Groupnum: pricingPlan?.GroupNum
          }])
        }).then((response) => response.json())
          .then((data) => {
            console.log(data);
            setIsPriceLoading(false);
            if(data[0].Data.PayResponse.ResponseTransactions[0].Pricing) {
              setPrice(data[0].Data.PayResponse.ResponseTransactions[0].Pricing.PatientPayAmt.toFixed(2));
              setPriceError(false);
            } else {
              setPriceError(true);
            }
            // TODO do something - error message ...?
          })
          .catch((err) => {
            console.log(err.message);
            setPriceError(true);
            setIsPriceLoading(false);
          }
        );
      }

      fetchPrice();
    }
  }, [medicationPricingMode, selectedPharmacy])

  const pharmaciesToDisplay = results.slice(0, displayCount);

  const handlePharmacySelect = (pharmacyId: number) => {
    console.log('(in Results) setting selected pharmacy to: ' + pharmacyId);
    setSelectedPharmacy(pharmacyId);
  }

  const handleShowMore = () => {
    setdisplayCount(displayCount + 10);
  }

  return (
    <PharmacyPricingModeContext.Provider value={contextValue}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={medicationPricingMode ? 12 : 6}>
          <Box>Showing {pharmaciesToDisplay.length} of {results.length} Results</Box>
          <Box sx={{overflow: 'auto', maxHeight: '70vh'}}>
            <PharmacyList 
              pharmacies={ pharmaciesToDisplay }
              onPharmacySelect={handlePharmacySelect}
              selectedPharmacy={selectedPharmacy}
            />
            <Button
              onClick={handleShowMore}
              disabled={pharmaciesToDisplay.length === results.length ? true : false}
              variant='contained'
              sx={{minWidth: '60%', mb: 2}}
            >
              Show More
            </Button>
          </Box>
        </Grid>
        { !medicationPricingMode ? (
          <>
          <Grid item xs={12} md={6}>
            { !pricingMode ? ( // if PricingMode is disabled (false) then show map, else show MedicationSearchForm with copayEstimate enabled
              <PharmacyMap
                pharmacies={ pharmaciesToDisplay }
                selectedPharmacy={ selectedPharmacy }
              />
            ) : (
              <>
              <Typography variant='h6' sx={{fontWeight: 'bold', textAlign: 'left', pl: 1}}>Find a Medication for Copay Estimate</Typography>
              <MedicationSearch />
              </>
            )}
          </Grid>
          <Box sx={{p:1, pt:2, fontStyle: 'italic', m: 'auto', pl: 2}}>
            Please note that health plans may choose to exclude certain pharmacies from coverage.
          </Box>
          </>
        ) : (
          price !== '' || priceError ? (
            <Grid item xs={12}>
            <Box sx={{
              p: 3,
              mx: 'auto',
              my: 2,
              background: theme.palette.secondary.main,
              color: 'white',
              borderRadius: '6px',
              width: '30%'
            }}>
              { !priceError ? (
                <>Estimated Copay: ${price}</>
              ) : (
                <>Estimated copay is not available. Please search for an alternative medication or contact MaxorPlus at 800-687-0707 for further assistance.</>
              )}
            </Box>
            { !priceError && (
              <Box sx={{textAlign: 'left'}}>
                <Typography variant='body2'>Pricing is determined based on your benefit plan policy and coverage provisions. The actual price you pay will be calculated at your pharmacy at the time of purchase. Some prescriptions may not be subject to cost sharing if billed as a preventative service.</Typography>
                <Typography variant='body2'>The amount you pay may be different for many reasons, including if:</Typography>
                {/* <Typography variant='body2'> */}
                  <ul className='disclaimer-list'>
                    <li>Drug prices change,</li>
                    <li>Your deductible or out-of-pocket totals are updated,</li>
                    <li>You take a brand drug when a generic equivalent is available,</li>
                    <li>You receive copay assistance or third-party assistance such as manufacturer's coupon, or</li>
                    <li>You receive your drug from an out-of-network pharmacy.</li>
                  </ul>
                {/* </Typography> */}
                <Typography variant='body2'>The pricing provided is an estimate and not a guarantee of coverage. Refer to your benefit plan documentation for more information.</Typography>
              </Box>
            )}
              <Box sx={{p:1, pt:2, fontStyle: 'italic', m: 'auto'}}>
                A MaxorPlus Member Advocate is available 24 hours a day at <a href="tel:+18006870707">800-687-0707</a> to help answer any questions.
              </Box>
            </Grid> 
          ) : isPriceLoading ? 
            <Box sx={{ py: 2, mx: 'auto', width: '100%' }}><CircularProgress size={50}/></Box> : null
        )}
      { medicationPricingMode && 
        <Grid item xs={12}>
        <Box sx={{p:1, pt:2, fontStyle: 'italic', m: 'auto'}}>
          Please note that health plans may choose to exclude certain pharmacies from coverage.
        </Box>
        </Grid>
      }
      </Grid>
    </PharmacyPricingModeContext.Provider>
  );
}

export default PharmacySearchResults;
