import { Alert, Box, CircularProgress, Grid, Snackbar } from "@mui/material";
import { useState } from "react";
import MedicationSearchForm from "./MedicationSearchForm";
import { IMedicationSearchParameters } from "../../types/IMedicationSearchParameters";
import { DEFAULT_FORMULARY, Formulary } from "../../types/Formulary";
import { trackGA4Event } from "../../utils/google-analytics/ga4";
import MedicationSearchResults from "./MedicationSearchResults";
import { Medication } from "../../types/Medication";
import { DEFAULT_PLAN, Plan } from "../../types/Plan";

const MedicationSearch = () => {
  const [medReturned, setMedReturned] = useState<Medication | null>(null);
  const [isLoading, setIsLoading] = useState(false); // TODO this should probably be moved into a service?
  const [alertMsg, setAlertMsg] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [plan, setPlan] = useState<Plan>(DEFAULT_PLAN);
  const [formulary, setFormulary] = useState<Formulary>(DEFAULT_FORMULARY);
  const [quantity, setQuantity] = useState('');
  const [daysSupply, setDaysSupply] = useState('');

  const handleSearch = async (params: IMedicationSearchParameters) => {
    console.log('Handling Search in MedicationSearch - using parameters: ', params);
    setMedReturned(null);
    setIsLoading( true );
    trackGA4Event(
      "medication_search",
      "search_submitted",
      window.location.hash,
      params
    );

    try {
      // TODO move this logic into a data service (or helper function? whatever react calls the pattern)
      const urlPrefix = process.env.REACT_APP_EXT_SVC_BASE_URL;
      const appToken = process.env.REACT_APP_APP_TOKEN;

      const drugDetailsRaw = await fetch(urlPrefix + '/LakerApi/GetDrugDetails', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          WebAccountLinkToken: appToken,
          NDC: params.medication.Ndcupchri
        })
      });

      const formularyInfoRaw = await fetch(urlPrefix + '/GetMedAssistFormularyDrugInfo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          AppToken: appToken,
          NDC: params.medication.Ndcupchri,
          FormularyWebId: params.formulary.FormularyWebId,
          FormularyId: params.formulary.FormularyId
        })
      });

      const drugDetails = await drugDetailsRaw.json();
      const formularyInfo = await formularyInfoRaw.json();
      console.log("drugDetails: ", drugDetails);
      console.log("formularyInfo: ", formularyInfo);
      setIsLoading(false);
      if( formularyInfo.StatusCode !== 'SUCCESS') {
        setAlertMsg('We encountered a problem getting medication information. Please contact MaxorPlus Customer Service at (800) 687-0707.');
        setOpenAlert(true);
      } else {
        const med = { ...params.medication, ...drugDetails.Data.Results, ...formularyInfo.MedAssistFormularyDrugs[0] };
        console.log('new medReturned: ', med);
        setMedReturned( med );
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setAlertMsg('We encountered a problem getting medication information. Please contact MaxorPlus Customer Service at (800) 687-0707.');
      setOpenAlert(true);
    }
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') { return; } 
    setOpenAlert(false);
  };
  
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ m: 'auto' }}>
          <MedicationSearchForm onSearchClick={handleSearch} plan={plan} setPlan={setPlan} formulary={formulary} setFormulary={setFormulary} quantity={quantity} setQuantity={setQuantity} daysSupply={daysSupply} setDaysSupply={setDaysSupply}/>
          <Snackbar open={openAlert} onClose={handleClose} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert variant="filled" severity="error">
              { alertMsg }
            </Alert>
          </Snackbar>
        </Grid>
        <Grid item xs={12}>
          {/*medReturned && <MedicationSearchResults med={medReturned} selectedPlan={plan} quantity={quantity} daysSupply={daysSupply} /> */}
          { isLoading ? <Box sx={{ pt: 6 }}><CircularProgress size={80}/></Box> :
             !isLoading && medReturned ?
              <MedicationSearchResults med={medReturned} selectedPlan={plan} quantity={quantity} daysSupply={daysSupply} /> : null // TODO replace null with error message??
          }
        </Grid>
      </Grid>
    </>
  );
};

export default MedicationSearch;