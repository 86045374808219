import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import logo from '../../assets/maxor_plus.svg';
import { useTheme } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '@mui/material';

const links = [
  {
    route: 'pharmacy-search',
    display: 'Find a Pharmacy'
  },
  {
    route: 'medication-search',
    display: 'Find a Medication'
  }
]

const Header = () => {
  const theme = useTheme();
  const route = useLocation().pathname;

  const linksList = links.map( link =>
    // <Button
    //   key={link.route}
    //   variant='text'
    //   size='large'
    //   sx={{ px: 2, mx: 2, textTransform: 'none' }}
    //   color={ route.endsWith(link.route) ? 'secondary' : 'inherit' }
    //   component={Link}
    //   to={link.route}
    // >
    //   {link.display}
    // </Button>
    <Link
      key={link.route}
      className={ route.endsWith(link.route) ? 'header-link active' : 'header-link' }
      // sx={{ px: 2, mx: 2, textTransform: 'none' }}
      to={link.route}
    >
      {link.display}
    </Link>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ bgcolor: theme.palette.background.paper }}>
        <Toolbar className='header' sx={{ borderBottom: 8, borderColor: theme.palette.secondary.main, pt:2, pb: 1 }}>
          <img src={logo} className="app-logo" alt="logo" />
          <div style={{'flexGrow': 1}}></div>
          { linksList }
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Header;