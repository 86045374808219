import { Box } from "@mui/material";
import { useParams } from "react-router-dom";

const PharmacyMapMessage = () => {
  let { carrier } = useParams();

  return (
    <Box sx={{p:1, pt:2, fontStyle: 'italic', m: 'auto'}}>
      { carrier === 'montana' ? (
        <>
          You may use the phone number or email address below to notify MaxorPlus of inaccurate directory information.
          <br /><br />
          <a href="tel:+18069055266">806-905-5266</a>
          <br />
          <a href="mailto:providerrelations@maxor.com">providerrelations@maxor.com</a>
        </>
        ) : (
          <>
            A MaxorPlus Member Advocate is available 24 hours a day at <a href="tel:+18006870707">800-687-0707</a> to help answer any questions.
          </>
        )
      }
    </Box> 
  )
};

export default PharmacyMapMessage;