import PharmacySearch from '../components/pharmacy-search/PharmacySearch';
import { trackGA4PageView } from '../utils/google-analytics/ga4';

const PharmacySearchPage = () => {
  trackGA4PageView('PharmacySearchPage');

  return (
    <>
      <PharmacySearch />
    </>
  );
};

export default PharmacySearchPage;