import PharmacyCard from './PharmacyCard';
import { Pharmacies, Pharmacy } from "../../types/Pharmacy";

interface PharmacyListProps {
  pharmacies: Pharmacies;
  onPharmacySelect: (params: number) => void;
  selectedPharmacy: number;
}

const PharmacyList = ( { pharmacies, onPharmacySelect, selectedPharmacy } : PharmacyListProps) => {

  const handleCardClick = (pharmacyId: number) => {
    console.log('setting selected pharmacy to: ' + pharmacyId);
    onPharmacySelect(pharmacyId);
  }

  const pharmacyList = pharmacies.map( ( pharmacy : Pharmacy ) => 
    <PharmacyCard 
      key={ pharmacy.id }
      pharmacy={ pharmacy }
      onCardClick={handleCardClick}
      isSelected={pharmacy.id === selectedPharmacy}
    />
  );

  return (
    <>
      { pharmacyList }
    </>
  );
}

export default PharmacyList;
