import './styles/App.css';
import Header from './components/common/Header';
import { Container, responsiveFontSizes, ThemeProvider } from '@mui/material';
import theme from './styles/materialTheme';
import Footer from './components/common/Footer';
import { Outlet, useLoaderData, useOutletContext } from 'react-router-dom';
import initGA4 from './utils/google-analytics/ga4';
import { AppData } from './types/AppData';
import { Plan, Plans } from './types/Plan';
import { Carrier } from './types/Carrier';

type ContextType = AppData;

export async function appLoader( params: any ) { //TODO: update type any?
  const urlPrefix = process.env.REACT_APP_EXT_SVC_BASE_URL;  

  // TODO should this fettch move into service/helper ??
  const carrier: Carrier = await fetch(urlPrefix + '/GetMedAssistCarrierSettingByUrlSuffix', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      AppToken: process.env.REACT_APP_APP_TOKEN,
      UrlSuffix: params.carrier
    })
  }).then((response) => response.json())
    .then((data) => {
      console.log(data);

      // TODO improve this logic - better error handling?
      return data[0];
    })
    .catch((err) => {
      console.log(err.message);
  });
  
  const plans: Plans = await fetch(urlPrefix + '/GetMedAssistPlanSettingByCarrier', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      AppToken: process.env.REACT_APP_APP_TOKEN,
      CarrierCode: carrier.CarrierCode
    })
  }).then((response) => response.json())
    .then((data) => {
      console.log(data);
      if(data.length === 1 && data.StatusMessage === 'NO RESULTS FOUND') {
        // TODO do something - error message ...?
      } else {
        let index = 1;
        const indexedPlans = data
                              .map( (plan:Plan) => ({...plan, id:index++}) )
                              .sort((a:Plan,b:Plan) => a.DisplayPlanName.localeCompare(b.DisplayPlanName)); // adding index to each pharmacy; sorted alpha by display name
        return indexedPlans;
      }
    })
    .catch((err) => {
      console.log(err.message);
    }
  );

  return {
    carrier: carrier,
    plans: plans
  }
}

function App() {
  initGA4(); // TODO figure out how to run init only once?

  const responsiveTheme = responsiveFontSizes(theme);

  const appData: any = useLoaderData(); // forced to use any because useLoaderData returns type unkown; TODO fix??
  // console.log('appData in app: ', appData);

  return (
    <div className="App">
      <ThemeProvider theme={responsiveTheme}>
        <Header />
        <Container maxWidth="xl" sx={{ minHeight: '85vh' }}>
          <Outlet context={appData satisfies ContextType}/>
        </Container>
        <Footer />
      </ThemeProvider>
    </div>
  );
}

export function useAppData() {
  return useOutletContext<ContextType>();
}

export default App;
