import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { Medication } from "../../types/Medication";
import stockPhoto from '../../assets/medication-search-stock.jpg';
import { IPricingModeContext, MedicationPricingModeContext, PricingData, usePharmacyPricingMode } from "../../utils/pricing-mode/PricingModeContext";
import theme from "../../styles/materialTheme";
import { useEffect, useState } from "react";
import PharmacySearch from "../pharmacy-search/PharmacySearch";
import { Plan } from "../../types/Plan";

interface MedicationSearchResultsProps {
  med: Medication;
  selectedPlan: Plan;
  quantity: string;
  daysSupply: string;
}

const MedicationSearchResults = ( { med, selectedPlan, quantity, daysSupply } : MedicationSearchResultsProps ) => {
  const [pharmacyPricingData, pharmacyPricingPlan, pharmacyPricingMode, setPharmacyPricingMode] = usePharmacyPricingMode(); // TODO: overlapping pricing modes is a bit confuising; maybe need to create separate components for medicationSearchResults that are displayed in the pharmacySearch for pricing mode..
  const [pricingMode, setPricingMode] = useState(false);
  const pricingData: PricingData = {
    ndc: med.Ndcupchri,
    drugType: med.DrugType,
    quantity: quantity,
    daysSupply: daysSupply
  }
  const contextValue: IPricingModeContext = [pricingData, selectedPlan, pricingMode, setPricingMode];
  const [price, setPrice] = useState('');
  const [priceError, setPriceError] = useState(false);
  const [isPriceLoading, setIsPriceLoading] = useState(false);

  useEffect(() => { // request pricing logic
    if( pharmacyPricingMode && pharmacyPricingData !== undefined ) {
      const urlPrefix = process.env.REACT_APP_EXT_SVC_BASE_URL;
      setPrice('');
      setPriceError(false);
      setIsPriceLoading(true);
      if(isCopayLimited) {
        setPriceError(true);
        setIsPriceLoading(false);
        return;
      }
      const fetchPrice = async () => {
        const pricingInfoResp = await fetch(urlPrefix + '/GetMedAssistPricingInfo', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ApplicationToken: process.env.REACT_APP_APP_TOKEN,
            SubscriberNum: pharmacyPricingPlan.SubscriberNum
          })
        });
        const pricingInfo = await pricingInfoResp.json();

        fetch(urlPrefix + '/LakerApi/GetPricingClaimVerbose', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify([{ // TODO update request object to match (see notepad++ ex)
            WebAccountLinkToken: process.env.REACT_APP_APP_TOKEN,
            RegionId: 23,               // does defaulting this to 23 work ??
            Physiciannpi: "1234567893", // This needs to be hard coded
            Override: false,            // hard code?
            Ndc: med.Ndcupchri,
            DrugType: med.DrugType,
            Quantity: quantity,
            Dayssupply: daysSupply,
            Id: pharmacyPricingData.pharmacyId, // Nabp + "-" + Npi
            Pharmacynpi: pharmacyPricingData.pharmacyNpi,
            Subscribernum: pharmacyPricingPlan?.SubscriberNum,
            Personcode: pricingInfo.PersonCode,
            Dob: pricingInfo.DOB, // TODO: check that I do not need to process the DOB at all
            LastName: pricingInfo.LastName,
            FirstName: pricingInfo.FirstName,
            Mi: pricingInfo.Mi,
            GenderCode: pricingInfo.Gendercode,
            Groupnum: pharmacyPricingPlan?.GroupNum
          }])
        }).then((response) => response.json())
          .then((data) => {
            console.log(data);
            setIsPriceLoading(false);
            if(data[0].Data.PayResponse.ResponseTransactions[0].Pricing) {
              setPrice(data[0].Data.PayResponse.ResponseTransactions[0].Pricing.PatientPayAmt.toFixed(2));
              setPriceError(false);
            } else {
              setPriceError(true);
            }
          })
          .catch((err) => {
            console.log(err.message);
            setPriceError(true);
            setIsPriceLoading(false);
          }
        );
      }
      
      fetchPrice();
    }
  }, [pharmacyPricingMode, med, pharmacyPricingData]);

  const getLimitStrings = (code:string) => {
    var defintions:any = {
      'ACA': "Covered by the plan at 100%",
      'BP': "Brand penalty, member may be responsible for the cost difference between brand and generic",
      'XF': "Medication is not covered by the Plan Formulary",
      'XB': "Medication is not covered by the Plan Benefit",
      'PA': "Medication requires prior authorization to confirm medical necessity prior to coverage",
      'QL': "Quantity limits may apply",
      'SP': "Specialty medication, this drug may only be available at the plan's preferred specialty pharmacy"
    };
    return (defintions[code.toUpperCase()] ?? 'Covered')
  };

  const limits = med.PublicNotes.split(',');
  const limitsDisplay = limits.map((s:string) => {
    return <li key={s}>{getLimitStrings(s)}</li>
  });

  const noCopayLimits = ['XB','XF','PA','SP'];
  const isCopayLimited = limits.some(i => noCopayLimits.includes(i));

  const getTierStrings = (code:number) => {
    var defintions: any = {
      1: "Tier 1 medication, typically generic medications available at lowest member cost",
      2: "Tier 2 medication, typically preferred brand medications",
      3: "Tier 3 medication, typically non-preferred brand medications",
      4: "Tier 4 medication, typically specialty medications",
      5: "Tier 5 medication, typically preferred specialty medications",
      6: "Tier 6 medication, typically non-preferred specialty medications"
    }
    return (defintions[code] ?? 'No Tier Found')
  };

  const tierDisplayText = getTierStrings(med.Tier);

  const handleActionButton = () => {
    if( pricingMode ) {
      setPricingMode(false);
    } else {
      console.log('Estimate Copay clicked for: ', med);
      setPricingMode(true);
    }
  }

  return (
    <MedicationPricingModeContext.Provider value={contextValue}>
    <Grid container spacing={4}>
      <Grid item xs={12} md={pharmacyPricingMode ? 12 : 6}>
        <Box sx={{ textAlign: 'left' }}>
          <Typography variant='h6' sx={{ fontWeight: 'bold'}}>Medication</Typography>
          <Typography variant='subtitle1'>{med.Proddescabbrev}</Typography>
          <Typography variant='body2'>Dosage Form: {med.DoseDescr}</Typography>
          <Typography variant='body2'>Therapy Class: {med.MaxorClass}</Typography>
        </Box>
        <Box sx={{ textAlign: 'left', mt: 1 }}>
          <Typography variant='h6' sx={{fontWeight: 'bold'}}>Coverage Information</Typography>
          <Typography variant='body2'>Limits/Restrictions:</Typography>
          <ul className="limits">
            {limitsDisplay}
          </ul>
          <Typography variant='body2'>{'Tier: ' + tierDisplayText}</Typography>
        </Box>
        <Box sx={{ textAlign: 'left', mt: 1 }}>
          <Typography variant='h6' sx={{fontWeight: 'bold'}}>Alternatives</Typography>
          <Typography variant='body2'>{ med.Alternatives || 'No alternatives found' }</Typography>
        </Box>
        <Box sx={{ textAlign: 'left', mt: 1, mb: 3 }}>
          <Typography variant='h6' sx={{fontWeight: 'bold'}}>Additional Information</Typography>
          <Typography variant='body2'>{med.CommonUses || 'Additional Drug Details Unavailable.'}</Typography>
        </Box>
        { !pharmacyPricingMode ? (
          <>
          <Button
            size="small"
            variant="contained"
            onClick={handleActionButton}
            disabled={pricingMode || isCopayLimited} // TODO: confirm this is desired behavior
          >
            Estimate Copay
          </Button>
          <Box sx={{ textAlign: 'left', mt: 6 }}>
            <Typography sx={{ fontStyle: 'italic', mt: 2 }}>
              Inclusion on the formulary does not guarantee coverage. Individual plans may vary, and some drugs not included on the formulary may be covered. Some drugs may be available only through your plan’s preferred pharmacy.
            </Typography>
            <Typography sx={{ fontStyle: 'italic', color: 'gray'}}>{'Last Updated: ' + med.AddDate.split('T')[0]}</Typography>
          </Box>
          </>
        ) : (
          price !== '' || priceError ? (
            <>
              <Box sx={{
                p: 3,
                marginLeft: 'auto',
                marginRight: 'auto',
                mb: 2,
                background: theme.palette.secondary.main,
                color: 'white',
                borderRadius: '6px',
                width: '30%'
              }}>
                { !priceError ? (
                  <>Estimated Copay: ${price}</>
                ) : (
                  <>Estimated copay is not available. Please search for an alternative medication or contact MaxorPlus at 800-687-0707 for further assistance.</>
                )}
              </Box>
              { !priceError && (
                <Box sx={{textAlign: 'left'}}>
                  <Typography variant='body2'>Pricing is determined based on your benefit plan policy and coverage provisions. The actual price you pay will be calculated at your pharmacy at the time of purchase. Some prescriptions may not be subject to cost sharing if billed as a preventative service.</Typography>
                  <Typography variant='body2'>The amount you pay may be different for many reasons, including if:</Typography>
                  {/* <Typography variant='body2'> */}
                    <ul className="disclaimer-list">
                      <li>Drug prices change,</li>
                      <li>Your deductible or out-of-pocket totals are updated,</li>
                      <li>You take a brand drug when a generic equivalent is available,</li>
                      <li>You receive copay assistance or third-party assistance such as manufacturer's coupon, or</li>
                      <li>You receive your drug from an out-of-network pharmacy.</li>
                    </ul>
                  {/* </Typography> */}
                  <Typography variant='body2'>The pricing provided is an estimate and not a guarantee of coverage. Refer to your benefit plan documentation for more information.</Typography>
                </Box>
              )}
          </>
          ) : isPriceLoading ? <Box sx={{ py: 2 }}><CircularProgress size={47}/></Box> : null
        )}
      </Grid>
      { !pharmacyPricingMode ? (
        <Grid item xs={12} md={6}>
          { !pricingMode ? (
            <>
            <Box 
              component='img'
              sx={{ width: '80%', mt: 3, mb: 1, display: { xs: 'none', md: 'inline'} }}
              src={stockPhoto}
            />
            <Box sx={{p:1, pt:2, fontStyle: 'italic', m: 'auto'}}>
              A MaxorPlus Member Advocate is available 24 hours a day at <a href="tel:+18006870707">800-687-0707</a> to help answer any questions.
            </Box>
            </>
          ) : (
            <>
            <Typography variant='h6' sx={{fontWeight: 'bold', textAlign: 'left', pl: 1}}>Find a Pharmacy for Copay Estimate</Typography>
            <PharmacySearch />
            </>
          )}
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Box sx={{p:1, pt:2, fontStyle: 'italic', m: 'auto'}}>
            A MaxorPlus Member Advocate is available 24 hours a day at <a href="tel:+18006870707">800-687-0707</a> to help answer any questions.
          </Box>
        </Grid>
      )}
    </Grid>
    </MedicationPricingModeContext.Provider>
  );
}

export default MedicationSearchResults;