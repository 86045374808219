import { Marker, Popup, useMap } from "react-leaflet";
import L, { LatLngExpression } from "leaflet";
import { Pharmacy } from "../../types/Pharmacy";
import DirectionsButton from "../common/DirectionsButton";
import { useEffect, useRef } from "react";

interface PharmacyMarkerProps {
  pharmacy: Pharmacy;
  isSelected: boolean;
}

const MontanaPharmacyMarker = ({pharmacy, isSelected} : PharmacyMarkerProps) => {
  const map = useMap();
  let popupRef: any = useRef(); // TODO: update type from any? but to what??

  useEffect( () => {
    if(isSelected) {
      showSelectedPharmacy();
    }
  },[isSelected]);

  const pMarker = L.divIcon({
    iconSize: [24, 24],
    iconAnchor: [12, 24],
    popupAnchor: [0, -24],
    html: pharmacy.id.toString()
  });

  const markerPos: LatLngExpression = [pharmacy.PharmLatitude, pharmacy.PharmLongitude];

  function showSelectedPharmacy() {
    console.log('focusing map on selected pharmacy ' + pharmacy.id + " @ ", markerPos);
    map.flyTo(markerPos, 13);
    popupRef.openOn(map); // open pharmacy popup
  }

  // TODO: update ref type from any? But to what??
  return (
    <Marker position={markerPos} icon={pMarker}>
      <Popup ref={(r: any) => { popupRef = r; }}>
        <h3>{pharmacy.PharmacyName}</h3>
        <DirectionsButton
          mapUrl={pharmacy.MapUrl}
          address={pharmacy.PharmAddr1 + (pharmacy.PharmAddr2??" ") +
            pharmacy.PharmCity + ", " + pharmacy.PharmState + " " + pharmacy.PharmZip}
        />
      </Popup>
    </Marker>
  )
}

export default MontanaPharmacyMarker;