import { useEffect, useState } from 'react';
import { TextField, Button, Paper, FormControl, InputLabel, MenuItem, Select, Grid, Skeleton, Box } from '@mui/material';
import { IPharmacySearchParameters } from '../../types/IPharmacySearchParameters';
import { Plan, Plans } from '../../types/Plan';
import texture from '../../assets/texture.svg';
import { useAppData } from '../../App';
import { useMedicationPricingMode } from '../../utils/pricing-mode/PricingModeContext';

interface PharmacySearchFormProps {
  onSearchClick: (params: IPharmacySearchParameters) => void;
  plan: Plan;
  setPlan: React.Dispatch<React.SetStateAction<Plan>>;
}

const radiusOptions = [
  { value: '5', display: '5 miles' },
  { value: '10', display: '10 miles' },
  { value: '15', display: '15 miles' },
  { value: '20', display: '20 miles' },
  { value: '25', display: '25 miles' },
  { value: '30', display: '30 miles' },
]

const PharmacySearchForm = ( { onSearchClick, plan, setPlan } : PharmacySearchFormProps ) => {
  const appData = useAppData();
  const [zipCode, setZipCode] = useState('');
  const [radius, setRadius] = useState('5'); // default radius set to 5
  const [plans, setPlans] = useState<Plans>([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [pricingData, pricingPlan, medicationPricingMode, setMedicationPricingMode] = useMedicationPricingMode();

  useEffect(() => {
    // console.log('appData in pharm search form: ', appData);
    setPlans(appData.plans);
    if( appData.plans.length === 1 ) { // preselects the first plan if only one plan is available
      setPlan(appData.plans[0]);
    }
  }, [appData]);

  const planSelectOptions = plans.map((plan: Plan) => (
    <MenuItem key={plan.PlanNum} value={plan.SubscriberNum}>
      {plan.DisplayPlanName}
    </MenuItem>
  ));

  const radiusSelectOptions = radiusOptions.map( r => (
    <MenuItem key={r.value} value={r.value}>
      {r.display}
    </MenuItem>
  ));

  const handleZipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.match(/[^0-9]/)) { // Check that input is numeric
      event.preventDefault();
    } else {
      setZipCode(event.target.value)
    }
  };

  // locally handles the search click before sending to parent
  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
      const params: IPharmacySearchParameters = {
        zipCode: zipCode,
        plan: medicationPricingMode ? pricingPlan.SubscriberNum : plan.SubscriberNum, // use the selectedPlan when pricing mode is enabled
        radius: radius
      };
      // console.log("Search triggered in the PharmacySearchForm.. with parms: ", params);
      onSearchClick( params ); // use parent callback function to trigger actual search
  };

  return (
    <Paper sx={{p: 2, m: 1, pr:{ xs: 0, md: 2} }}>
      <Grid container spacing={2}>
        { !medicationPricingMode ? ( // hide texture when pricing mode enabled
        <Grid item xs={12} md={6}>
          <img src={texture} />
        </Grid>
        ) : null }
        <Grid item
          xs={12} md={medicationPricingMode ? 12 : 6}
          sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: { xs: 'column', md: 'row'},
              gap: { xs: '16px', md: 0}
            }}
          component='form'
          onSubmit={handleSearch}
        >
          { dataLoading ? (
            <>
            <Skeleton variant='rounded' sx={{mx: 2}} width={165} height={56}></Skeleton>
            <Skeleton variant='rounded' sx={{mx: 2}} width={110} height={56}></Skeleton>
            <Skeleton variant='rounded' sx={{mx: 2}} width={250} height={56}></Skeleton>
            <Skeleton variant='rounded' sx={{mx: 2}} width={95} height={56}></Skeleton>
            </>
          ) : (
            <> 
              <TextField
                sx={{mx: 2, bgcolor: 'white', flex: 1}}
                inputProps={{maxLength: 5}}
                label="Enter Zip Code"
                value={zipCode}
                onChange={handleZipChange}
                autoFocus
              />
              <FormControl sx={{minWidth: '16%', textAlign: 'left', flex: 1}}>
                <InputLabel id="radius-select-label" sx={{ml: 2}}>Select a Radius</InputLabel>
                <Select
                  labelId="radius-select-label"
                  id="radius-select"
                  sx={{mx: 2, bgcolor: 'white'}}
                  value={radius}
                  label="Select a Radius"
                  autoWidth
                  onChange={(e) => setRadius(e.target.value)}
                >
                  {radiusSelectOptions}
                </Select>
              </FormControl>
              { !medicationPricingMode ? ( // hide Plan dropdown when pricing mode enabled; plan is set by pharmacy search and passed down
              <FormControl sx={{minWidth: '24%', textAlign: 'left', flex: 1}}>
                <InputLabel id="plan-select-label" sx={{ml: 2}}>Select a Plan</InputLabel>
                <Select
                  labelId="plan-select-label"
                  id="plan-select"
                  sx={{mx: 2, bgcolor: 'white'}}
                  value={plan.SubscriberNum}
                  label="Select a Plan"
                  autoWidth
                  onChange={(e) => setPlan( plans.find(p => p.SubscriberNum === e.target.value) as Plan )}
                >
                  {planSelectOptions}
                </Select>
              </FormControl>
              ) : null }
              <Button
                variant="contained"
                type="submit"
                disabled={ zipCode === '' || plan.SubscriberNum === '' && !medicationPricingMode ? true : false }
                sx={{mx: 2, px: 3, py: 2}}
              >
                Search
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PharmacySearchForm;