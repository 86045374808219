import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App, { appLoader } from './App';
import { Link, RouterProvider, createBrowserRouter } from 'react-router-dom';
import ErrorPage from './pages/ErrorPage';
import LandingPage from './pages/LandingPage';
import PharmacySearchPage from './pages/PharmacySearchPage';
import MedicationSearchPage from './pages/MedicationSearchPage';
import MontanaApp, { montanaLoader } from './components/montana/MontanaApp';

const router = createBrowserRouter([
  {
    path: '/',
    element: <><Link to={'/test-'}>TEST</Link> | <Link to={'/pharmacy-search/montana'}>MONTANA</Link></> // TODO replace element with simple landing page?
  },
  {
    path: '/pharmacy-search/montana',
    element: <MontanaApp />,
    errorElement: <ErrorPage />,
    loader: montanaLoader
  },
  {
    path: '/:carrier',
    id: 'root',
    element: <App />,
    errorElement: <ErrorPage />,
    loader: ({params}) => appLoader(params),
    children: [
      { index: true, element: <LandingPage /> },
      {
        path: 'pharmacy-search',
        element: <PharmacySearchPage />,
      },
      {
        path: 'medication-search',
        element: <MedicationSearchPage />,
      }
    ]
  }
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
