import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import stockPhoto from '../../assets/landing-page-stock.jpg';
import pharmacyIcon from '../../assets/Icon-FindPharmacy.png'
import medIcon from '../../assets/Icon-FindMedication.png'

const LandingPageComponent = () => {

    return (
      <>
        <Grid container spacing={4}>
          <Grid item xs={4} sx={{ display: { xs: 'none', md: 'block'}}}>
            <Box 
              component='img'
              mt={3}
              mb={1}
              sx={{ width: '100%' }}
              src={stockPhoto}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Box
              mt={6}
              p={1}
              sx={{ border: '2px black', borderStyle: 'solid none' }}>
              <Typography variant='h3'>Welcome to the Med Assist Search Tool!</Typography>
              <p>This portal lets you search for in-network pharmacies, verify coverage of medications, and find estimated copays. You do not need to register for an account.
              </p>
            </Box>
            <Box mt={4} sx={{ display: 'flex' }}>
              <Box
                className='landing-page-link'
                sx={{ flexGrow: 1, p: {xs: 2, md: 4}, mx: 2, borderRadius: '6px' }}
                component={Link}
                to={'pharmacy-search'}
              >
                <Typography variant='h4'>Find a Pharmacy</Typography>
                <Box 
                  component='img'
                  sx={{ maxWidth: {xs: '20vw', md: '256px'} }}
                  src={pharmacyIcon}
                />
                <p>Search in-network pharmacies</p>
              </Box>
              <Box
                className='landing-page-link'
                sx={{ flexGrow: 1, p: {xs: 2, md: 4}, mx: 2, borderRadius: '6px' }}
                component={Link}
                to={'medication-search'}
              >
                <Typography variant='h4'>Find a Medication</Typography>
                <Box 
                  component='img'
                  sx={{ maxWidth: {xs: '20vw', md: '256px'} }}
                  src={medIcon}
                />
                <p>Search medication coverage</p>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };
  
  export default LandingPageComponent;