import { useEffect, useState } from 'react';
import { TextField, Button, Paper, FormControl, InputLabel, MenuItem, Select, Grid, Skeleton, Autocomplete, CircularProgress } from '@mui/material';
import { Plan, Plans } from '../../types/Plan';
import { DEFAULT_FORMULARY, Formularies, Formulary } from '../../types/Formulary';
import { IMedicationSearchParameters } from '../../types/IMedicationSearchParameters';
import texture from '../../assets/texture.svg';
import { usePharmacyPricingMode } from '../../utils/pricing-mode/PricingModeContext';
import { useAppData } from '../../App';
import { Medication } from '../../types/Medication';

interface MedicationSearchFormProps {
  onSearchClick: (params: IMedicationSearchParameters) => void;
  plan: Plan;
  setPlan: React.Dispatch<React.SetStateAction<Plan>>;
  formulary: Formulary;
  setFormulary: React.Dispatch<React.SetStateAction<Formulary>>;
  quantity: string;
  setQuantity: React.Dispatch<React.SetStateAction<string>>;
  daysSupply: string;
  setDaysSupply: React.Dispatch<React.SetStateAction<string>>;
}

const MedicationSearchForm = ( { onSearchClick, plan, setPlan, formulary, setFormulary, quantity, setQuantity, daysSupply, setDaysSupply } : MedicationSearchFormProps ) => {
  const appData = useAppData();
  const [selectedMed, setSelectedMed] = useState<Medication | null>(null);
  const [plans, setPlans] = useState<Plans>([]);
  const [formularies, setFormularies] = useState<Formularies>([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [medicationInput, setMedicationInput] = useState('');
  const [options, setOptions] = useState<readonly any[]>([]);
  const [autoCompleteLoading, setAutoCompleteLoading] = useState(false);
  const [pricingData, pricingPlan, pharmacyPricingMode, setPharmacyPricingMode] = usePharmacyPricingMode();

  useEffect(() => { // grab available plans from appData
    setPlans(appData.plans);
    if( appData.plans.length === 1 ) { // preselects the first plan if only one plan is available
      setPlan(appData.plans[0]);
    }
  }, [appData]);

  useEffect(() => { // load data for Formulary dropdown options
    if( plan.PlanNum !== 0 || pharmacyPricingMode && pricingPlan.PlanNum !== 0 ) {
      let currentPlan = pharmacyPricingMode ? pricingPlan.PlanNum : plan.PlanNum;
      setFormulary(DEFAULT_FORMULARY);
      const urlPrefix = process.env.REACT_APP_EXT_SVC_BASE_URL;
      // setDataLoading(true);
      fetch(urlPrefix + '/GetMedAssistFormularyInfoByPlan', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          AppToken: process.env.REACT_APP_APP_TOKEN,
          // PlanNum: plan.PlanNum
          PlanNum: currentPlan
        })
      }).then((response) => response.json())
        .then((data) => {
          console.log(data);
          if(data.length === 1 && data.StatusMessage === 'NO RESULTS FOUND') {
            // TODO do something - error message ...?
          } else {
            data = data.sort((a:Formulary, b:Formulary) => a.FormularyDisplayName.localeCompare(b.FormularyDisplayName));
            setFormularies(data);
            if( data.length === 1 ) { // preselects the first option if only one is available
              setFormulary(data[0]);
            }
            // setDataLoading(false);
          }
        })
        .catch((err) => {
          console.log(err.message);
        }
      );
    }
  }, [plan, pricingPlan]);

  const planSelectOptions = plans.map((plan: Plan) => (
    <MenuItem key={plan.PlanNum} value={plan.SubscriberNum}>
      {plan.DisplayPlanName}
    </MenuItem>
  ));

  const formularySelectOptions = formularies.map((f: Formulary) => (
    <MenuItem key={f.FormularySettingId} value={f.FormularyDisplayName}>
      {f.FormularyDisplayName || f.FormularyWebId}
    </MenuItem>
  ));

  // locally handles the search click before sending to parent
  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if( selectedMed !== null ) {
      const params: IMedicationSearchParameters = {
        medication: selectedMed,
        quantity: quantity,
        daySupply: daysSupply,
        plan: pharmacyPricingMode ? pricingPlan.SubscriberNum : plan.SubscriberNum, // use the pricingPlan when pricing mode is enabled
        formulary: formulary
      };
      onSearchClick( params ); // use parent callback function to trigger actual search
    }
  };

  useEffect(() => {
    if (medicationInput.length > 3) {
      setAutoCompleteLoading(true);
      const fetchOptions = async () => {
        try {
          const urlPrefix = process.env.REACT_APP_EXT_SVC_BASE_URL;
          const response = await fetch(urlPrefix + '/LakerApi/DrugSearch', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              WebAccountLinkToken: '3eec7922-97ed-46ee-8351-8de980bfb69e', // TODO: check if this is still needed?
              Prodname: medicationInput
            })
          });
          const data = await response.json();
          setOptions(data.Data.Drugs);
        } catch (error) {
          console.error('Error fetching options:', error);
          setOptions([]);
        } finally {
          setAutoCompleteLoading(false);
        }
      };

      // Debounce the fetch to reduce unnecessary API calls
      const timeoutId = setTimeout(fetchOptions, 500);

      // Cleanup function to cancel the timeout if input changes
      return () => clearTimeout(timeoutId);
    } else {
      setOptions([]);
    }
  }, [medicationInput]);

  const handleAutoCompleteOnChange = (event: any, newValue: any) => {
    console.log("event: ", event);
    console.log("newValue: ", newValue);
    // setOptions(newValue ? [newValue, ...options] : options); // TODO verify this logic
    setSelectedMed(newValue);
  }

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const regex = /^\d{0,5}$/;
    
    if (regex.test(input)) {
      setQuantity(input);
    }
  };

  const handleDaysSupplyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const regex = /^\d{0,3}$/;

    if (regex.test(input)) {
      setDaysSupply(input);
    }
  };

  return (
    <Paper sx={{p: 2, m: 1, pr:{ xs: 0, md: 2} }}>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} md={4}>
          <img src={texture} />
        </Grid> */}
        <Grid item
          xs={12} md={12}
          sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: pharmacyPricingMode ? 'column' : { xs: 'column', md: 'row'},
              gap: pharmacyPricingMode ? '16px' : { xs: '16px', md: 0}
            }}
          component='form'
          onSubmit={handleSearch}
        >
          { dataLoading ? (
            <>
            <Skeleton variant='rounded' sx={{mx: 2}} width={200} height={56}></Skeleton>
            <Skeleton variant='rounded' sx={{mx: 2}} width={110} height={56}></Skeleton>
            <Skeleton variant='rounded' sx={{mx: 2}} width={110} height={56}></Skeleton>
            <Skeleton variant='rounded' sx={{mx: 2}} width={250} height={56}></Skeleton>
            <Skeleton variant='rounded' sx={{mx: 2}} width={250} height={56}></Skeleton>
            <Skeleton variant='rounded' sx={{mx: 2}} width={95} height={56}></Skeleton>
            </>
          ) : (
            <>
            <Autocomplete
              sx={{ flex: 1, mx: 2 }}
              inputValue={medicationInput}
              onInputChange={(event, newInputValue) => { setMedicationInput(newInputValue); }}
              clearOnBlur={false} //TODO check that we want this functionality
              value={selectedMed}
              // onChange={handleAutoCompleteOnChange}
              onChange={(event, newInputValue) => { setSelectedMed(newInputValue); }}
              options={options}
              loading={autoCompleteLoading}
              noOptionsText={medicationInput.length > 3 && options.length === 0 && !autoCompleteLoading ? 
                "No medications found. Please try another search." : medicationInput
              }
              // renderInput={(params) => (
              //   <TextField {...params} label="Enter a Medication Name" sx={{bgcolor: 'white'}}/>
              // )}
              renderInput={(params) => (
                <TextField 
                  {...params}
                  label="Enter a Medication Name"
                  sx={{bgcolor: 'white'}}
                  autoFocus
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {autoCompleteLoading ? <CircularProgress color="primary" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              getOptionLabel={(option:any) => typeof option === 'string' ? option : option.Proddescabbrev }
              filterOptions={(x:any) => x}
              includeInputInList
              filterSelectedOptions
              autoFocus
            />
            <TextField
              sx={{mx: 2, bgcolor: 'white'}}
              inputProps={{
                  maxLength: 5,
                  inputMode: 'numeric',
                  pattern: '\\d{0,5}'
                }}
              label="Enter a Quantity"
              value={quantity}
              onChange={handleQuantityChange}
            />
            <TextField
              sx={{mx: 2, bgcolor: 'white'}}
              inputProps={{
                maxLength: 3,
                inputMode: 'numeric',
                pattern: '\\d{0,3}'
              }}
              label="Enter a Days Supply"
              value={daysSupply}
              onChange={handleDaysSupplyChange}
            />
            { !pharmacyPricingMode ? ( // hide Plan dropdown when pricing mode enabled; plan is set by pharmacy search and passed down
              <FormControl sx={{minWidth: '20%', textAlign: 'left'}}>
                <InputLabel id="plan-select-label" sx={{ml: 2}}>Select a Plan</InputLabel>
                <Select
                  labelId="plan-select-label"
                  id="plan-select"
                  sx={{mx: 2, bgcolor: 'white'}}
                  value={plan?.SubscriberNum}
                  label="Select a Plan"
                  autoWidth
                  onChange={(e) => setPlan( plans.find(p => p.SubscriberNum === e.target.value) as Plan)}
                >
                  {planSelectOptions}
                </Select>
              </FormControl>
            ) : null }
            <FormControl sx={{minWidth: '20%', textAlign: 'left'}}>
              <InputLabel id="formulary-select-label" sx={{ml: 2}}>Select a Formulary</InputLabel>
              <Select
                labelId="formulary-select-label"
                id="formulary-select"
                sx={{mx: 2, bgcolor: 'white'}}
                value={formulary.FormularyDisplayName}
                label="Select a Formulary"
                autoWidth
                onChange={(e) => setFormulary( formularies.find(f => f.FormularyDisplayName === e.target.value) as Formulary)}
              >
                {formularySelectOptions}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              type="submit"
              disabled={ selectedMed === null || quantity === '' || daysSupply === '' || plan.SubscriberNum === '' && !pharmacyPricingMode || formulary.FormularyId === '' ? true : false }
              sx={{mx: 2, px: 3, py: 2}}
            >
              Search
            </Button>
            </>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MedicationSearchForm;