import React from "react";
import { DEFAULT_PLAN, Plan } from "../../types/Plan";

export type PricingData = { 
  ndc?: string,
  drugType?: string,
  quantity?: string,
  daysSupply?: string,
  pharmacyId?: string,
  pharmacyNpi?: string
};
export type IPricingModeContext = [PricingData | undefined, Plan, boolean, React.Dispatch<React.SetStateAction<boolean>>]; // pricing data, plan, pricingMode, setPricingMode

export const PharmacyPricingModeContext = React.createContext<IPricingModeContext>([undefined, DEFAULT_PLAN, false, () => null]);
export const MedicationPricingModeContext = React.createContext<IPricingModeContext>([undefined, DEFAULT_PLAN, false, () => null]);

export const usePharmacyPricingMode = () => {
  const context = React.useContext(PharmacyPricingModeContext);

  if (!context) {
    throw new Error("usePharmacyPricingMode can only be used within a component wrapped by a parent component implementing <PharmacyPricingModeContext.Provider ../> !");
  }

  return context;
}

export const useMedicationPricingMode = () => {
  const context = React.useContext(MedicationPricingModeContext);

  if (!context) {
    throw new Error("useMedicationPricingMode can only be used within a component wrapped by a parent component implementing <MedicationPricingModeContext.Provider ../> !");
  }

  return context;
}