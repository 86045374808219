import MedicationSearch from '../components/medication-search/MedicationSearch';
import { trackGA4PageView } from '../utils/google-analytics/ga4';

const MedicationSearchPage = () => {
  trackGA4PageView('MedicationSearchPage');

  return (
    <>
      <MedicationSearch />
    </>
  );
};

export default MedicationSearchPage;