import { LatLngExpression } from "leaflet";
import { Pharmacies, Pharmacy } from "../../types/Pharmacy";
import { MapContainer, TileLayer } from 'react-leaflet';
import MontanaPharmacyMarker from "./MontanaPharmacyMarker";

interface PharmacyMapProps {
  pharmacies: Pharmacies;
  selectedPharmacy: number;
}

const MontanaPharmacyMap = ( { pharmacies, selectedPharmacy } : PharmacyMapProps ) => {
  // TODO verify uisng the first pharmacy is a viable way to center the map
  const mapCenter: LatLngExpression = [pharmacies[0].PharmLatitude, pharmacies[0].PharmLongitude];

  const markerList = pharmacies.map( ( pharmacy : Pharmacy ) => 
    <MontanaPharmacyMarker
      pharmacy={pharmacy}
      key={pharmacy.Nabp}
      isSelected={pharmacy.id === selectedPharmacy}
    />
  );

  // TODO: should the zoom variable on Map be dynamic based on the search radius used??
  return (
    <div id="map">
      <MapContainer center={mapCenter} zoom={10} scrollWheelZoom={false}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        { markerList }
      </MapContainer>
      <>
          You may use the phone number or email address below to notify MaxorPlus of inaccurate directory information.
          <br /><br />
          <a href="tel:+18069055266">806-905-5266</a>
          <br />
          <a href="mailto:providerrelations@maxor.com">providerrelations@maxor.com</a>
        </>
    </div>
  );
}

export default MontanaPharmacyMap;
