import { Button } from "@mui/material";

interface DirectionsButtonProps {
    address: string | null;
    mapUrl: string | URL | undefined; // MapUrl values are not working to open link .. 
}

const DirectionsButton = ( {address, mapUrl} : DirectionsButtonProps  ) => {
  let isButtonDisabled = false;
  if( (address === null || address === '') && 
        (mapUrl === undefined || mapUrl === '') ) {
    isButtonDisabled = true;
  }

  const handleClick = () => {
    // alert("Giving directions now... turn left!");
    window.open('http://maps.google.com/?q=' + address);
  }

  return (
    <>
      <Button
        size="small"
        onClick={handleClick}
        disabled={isButtonDisabled}
      >
        Directions
      </Button>
    </>
  )
}

export default DirectionsButton;