import { useState, useEffect } from 'react';
import { Alert, Box, CircularProgress, Grid, Snackbar, TextField, Button, Paper, FormControl, InputLabel, MenuItem, Select, Skeleton } from '@mui/material';
import { Pharmacies, Pharmacy } from "../../types/Pharmacy";
import { IPharmacySearchParameters } from '../../types/IPharmacySearchParameters';
import { Plan, Plans } from '../../types/Plan';
import { trackGA4Event, trackGA4PageView } from '../../utils/google-analytics/ga4';
import MontanaPharmacyCard from './MontanaPharmacyCard';
import { useLoaderData } from 'react-router-dom';
import MontanaPharmacyMap from './MontanaPharmacyMap';

const radiusOptions = [
  { value: '5', display: '5 miles' },
  { value: '10', display: '10 miles' },
  { value: '15', display: '15 miles' },
  { value: '20', display: '20 miles' },
  { value: '25', display: '25 miles' },
  { value: '30', display: '30 miles' },
]

const MontanaPharmacySearch = () => {
  trackGA4PageView('PharmacySearchPage');

  const [pharmaciesReturned, setpharmaciesReturned] = useState<Pharmacies>([]);
  const [state, setState] = useState('loading'); // 'loading', 'idle', 'searching'
  const [alertMsg, setAlertMsg] = useState('');
  const [openAlert, setOpenAlert] = useState(false);

  const appData: any = useLoaderData();
  const [zipCode, setZipCode] = useState('');
  const [plan, setPlan] = useState('');
  const [radius, setRadius] = useState('5'); // default radius set to 5
  const [plans, setPlans] = useState<Plans>([]);

  const [displayCount, setdisplayCount] = useState(10);
  const [selectedPharmacy, setSelectedPharmacy] = useState(-1);
  const [pharmaciesToDisplay, setPharmaciesToDisplay] = useState<Pharmacies>([]);

  useEffect(() => {
    // console.log('appData in pharm search form: ', appData);
    setPlans(appData.plans);
    if( appData.plans.length === 1 ) { // preselects the first plan if only one plan is available
      setPlan(appData.plans[0].SubscriberNum);
    }
    setState('idle');
  }, [appData]);

  const planSelectOptions = plans.map((plan: Plan) => (
    <MenuItem key={plan.PlanNum} value={plan.SubscriberNum}>
      {plan.DisplayPlanName}
    </MenuItem>
  ));

  const radiusSelectOptions = radiusOptions.map( r => (
    <MenuItem key={r.value} value={r.value}>
      {r.display}
    </MenuItem>
  ));

  const handleZipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.match(/[^0-9]/)) { // Check that input is numeric
      event.preventDefault();
    } else {
      setZipCode(event.target.value)
    }
  };

  const handleSearch = async () => {
    const params: IPharmacySearchParameters = {
      zipCode: zipCode,
      plan: plan,
      radius: radius
    };
    console.log('Handling Search in PharmacySearch - using parameters: ', params);
    setpharmaciesReturned([]);
    setState('searching');
    trackGA4Event(
      "pharmacy_search",
      "search_submitted",
      window.location.hash,
      params
    );
    
    try {
      // TODO move this logic into a data service (or helper function? whatever react calls the pattern)
      const urlPrefix = process.env.REACT_APP_EXT_SVC_BASE_URL;
      const raw = await fetch(urlPrefix + '/LakerApi/GetInNetworkPharmaciesByRadius', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          Radius: params.radius,
          Zipcode: params.zipCode,
          Subscribernum: params.plan,
          UserAddress: {
            Address: '',
            City: '',
            State: null,
            Zipcode: params.zipCode
          }
        })
      });
      const data = await raw.json();
      console.log("data: ", data);
      setState('idle');
      if( data.Data.length === 0 ) {
        setAlertMsg('No pharmacies were found near the zip code you have entered, or the zip code is invalid. Please try again.');
        setOpenAlert(true);
      } else {
        let index = 1;
        const pharmacies = data.Data.map( (pharmacy:Pharmacy) => ({...pharmacy, id:index++}) ); // adding index to each pharmacy
        setpharmaciesReturned( pharmacies );
      }
    } catch (err) {
      console.log(err);
      setState('idle');
      setAlertMsg('We encountered a problem getting pharmacy information. Please contact MaxorPlus Customer Service at (800) 687-0707.');
      setOpenAlert(true);
    }
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') { return; } 
    setOpenAlert(false);
  };

  const handlePharmacySelect = (pharmacyId: number) => {
    console.log('(in MontanaPharmacySearch) setting selected pharmacy to: ' + pharmacyId);
    setSelectedPharmacy(pharmacyId);
  }

  const handleShowMore = () => {
    setdisplayCount(displayCount + 10);
  }

  // update pharmaciesToDisplay
  useEffect(() => {
    setPharmaciesToDisplay(pharmaciesReturned.slice(0, displayCount))
  },[pharmaciesReturned, displayCount]);

  const pharmacyList = pharmaciesToDisplay.map( ( pharmacy : Pharmacy ) => 
    <MontanaPharmacyCard 
      key={ pharmacy.id }
      pharmacy={ pharmacy }
      onCardClick={handlePharmacySelect}
      isSelected={pharmacy.id === selectedPharmacy}
    />
  );

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ m: 'auto' }}>
          <Paper sx={{p: 2, m: 1, pr:{ xs: 0, md: 2} }}>
            <Grid container spacing={2}>
              <Grid item 
                xs={12} md={4}
                sx={{
                  bgcolor: 'primary.main',
                  color: 'white',
                  mb: {xs: '0', md: '-16px'},
                  borderTopLeftRadius: '4px',
                  borderTopRightRadius: {xs: '4px', md: '0'},
                  borderBottomLeftRadius: {xs: '0', md: '4px'}
                }}
              >
                <h2 style={{margin: 0}}>Find a Pharmacy</h2>
                <p style={{margin: 0}}>Montana Residents</p>
              </Grid>
              <Grid item
                xs={12} md={8}
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexDirection: { xs: 'column', md: 'row'},
                    gap: { xs: '16px', md: 0}
                  }}
                >
                { state === 'loading' ? ( // TODO fix so this only shows on page load and not search
                  <>
                  <Skeleton variant='rounded' sx={{mx: 2}} width={165} height={56}></Skeleton>
                  <Skeleton variant='rounded' sx={{mx: 2}} width={110} height={56}></Skeleton>
                  <Skeleton variant='rounded' sx={{mx: 2}} width={250} height={56}></Skeleton>
                  <Skeleton variant='rounded' sx={{mx: 2}} width={95} height={56}></Skeleton>
                  </>
                ) : (
                  <>
                  <TextField
                    sx={{mx: 2, bgcolor: 'white'}}
                    inputProps={{maxLength: 5}}
                    label="Enter Zip Code"
                    value={zipCode}
                    onChange={handleZipChange}
                  />
                  <FormControl sx={{minWidth: '15%', textAlign: 'left'}}>
                    <InputLabel id="radius-select-label" sx={{ml: 2}}>Select a Radius</InputLabel>
                    <Select
                      labelId="radius-select-label"
                      id="radius-select"
                      sx={{mx: 2, bgcolor: 'white'}}
                      value={radius}
                      label="Select a Radius"
                      autoWidth
                      onChange={(e) => setRadius(e.target.value)}
                    >
                      {radiusSelectOptions}
                    </Select>
                  </FormControl>
                  <FormControl sx={{minWidth: '20%', textAlign: 'left'}}>
                    <InputLabel id="plan-select-label" sx={{ml: 2}}>Select a Plan</InputLabel>
                    <Select
                      labelId="plan-select-label"
                      id="plan-select"
                      sx={{mx: 2, bgcolor: 'white'}}
                      value={plan}
                      label="Select a Plan"
                      autoWidth
                      onChange={(e) => setPlan(e.target.value)}
                    >
                      {planSelectOptions}
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    onClick={ handleSearch }
                    disabled={ zipCode === '' || plan === '' ? true : false }
                    sx={{mx: 2, px: 3, py: 2}}
                  >
                    Search
                  </Button>
                  </>
                )}
              </Grid>
            </Grid>
          </Paper>
          <Snackbar open={openAlert} onClose={handleClose} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert variant="filled" severity="error">
              { alertMsg }
            </Alert>
          </Snackbar>
        </Grid>
        <Grid item xs={12}>
          { state === 'searching' ? <Box sx={{ pt: 6 }}><CircularProgress size={80}/></Box> :
              state === 'idle' && pharmaciesReturned.length > 0 && pharmaciesToDisplay.length > 0 ? (
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6} sx={{ m: 'auto' }}>
                    <Box>Showing {pharmaciesToDisplay.length} of {pharmaciesReturned.length} Results</Box>
                    <Box sx={{overflow: 'auto', maxHeight: '70vh'}}>
                      { pharmacyList }
                      <Button
                        onClick={handleShowMore}
                        disabled={pharmaciesToDisplay.length === pharmaciesReturned.length ? true : false}
                        variant='contained'
                        sx={{minWidth: '60%', mb: 2}}
                      >
                        Show More
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MontanaPharmacyMap
                      pharmacies={pharmaciesToDisplay}
                      selectedPharmacy={selectedPharmacy}
                    />
                  </Grid>
                  <Box sx={{p:1, pt:2, fontStyle: 'italic', m: 'auto', pl: 2}}>
                    Please note that health plans may choose to exclude certain pharmacies from coverage.
                  </Box>
                </Grid>
              ) : null // TODO replace null with error message??
          }
        </Grid>
      </Grid>
    </>
  );
};

export default MontanaPharmacySearch;