import ReactGA4 from "react-ga4";

// Initialize Googe Analytics 4 
const initGA4 = () => {
  ReactGA4.initialize(process.env.REACT_APP_GOOGLE_TAG || '');
  console.log("GA4 INITIALIZED");
};

// Send Event to GA4
const trackGA4Event = (
  category: string,
  eventName: string,
  label: string,
  data: any // TODO: does type any need to be changed?
) => {

  console.log("GA event:", category, ":", eventName, ":", label);

  let eventParams = {
    category,
    label,
    ...data
  };

  ReactGA4.event(eventName, eventParams);
};

const trackGA4PageView = ( title: string ) => {
  ReactGA4.send({
    hitType: 'pageview',
    page: window.location.hash,
    title: title
  });
}

export default initGA4;
export { initGA4, trackGA4Event, trackGA4PageView };