import LandingPageComponent from "../components/landing-page/LandingPage";


const LandingPage = () => {

  return (
    <>
      <LandingPageComponent />
    </>
  );
};

export default LandingPage;