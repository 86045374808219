import { Box, useTheme } from "@mui/material";

const staticFooterLinks = [
  {
    href: 'https://www.maxor.com/website-hipaa-notice-privacy-practices/',
    displayText: 'HIPAA Notice of Privacy Practices'
  },
  {
    href: 'https://www.maxor.com/website-privacy-policy/',
    displayText: 'Website Privacy Policy'
  },
  {
    href: 'https://www.maxor.com/maxorplus/',
    displayText: 'Learn About MaxorPlus'
  },
  {
    href: 'https://www.maxor.com/maxorplus/contact-us',
    displayText: 'Contact Us'
  }
]

const Footer = () => {
  const theme = useTheme();

  // TODO: add logic for adding dynamic footer links
  const footerLinksList = staticFooterLinks.map( footer =>
    <a className="footer-link" href={footer.href} target="_blank" rel="noreferrer" key={footer.href}>
      {footer.displayText}
    </a>
  );

  return (
    <Box sx={{
        p:2,
        flexGrow: 1,
        // bgcolor: theme.palette.primary.main, // TODO: update background color to charcoal grey
        bgcolor: '#373737',
        color: 'white'
      }}>
      <Box sx={{ pt: 2, borderTop: '1px solid white' }}>
        {/* <FooterLinkBar links={footerLinks} /> 
        TODO figure out how to iterate and add | lines between links*/}
        { footerLinksList }
      </Box>
      <Box pt={1}>© 2024 Maxor National Pharmacy Services, LLC. All Rights Reserved.</Box>
    </Box>
  )
}

const FooterLinkBar = (links: HTMLElement[]) => {


  return (

    <div></div>
  )
}

export default Footer;