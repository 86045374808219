export type Plan = {
    id: number,
    CarrierCode: string,
    DisplayPlanName: string,
    IsActive: boolean,
    PersonCode: string,
    PlanName: string,
    PlanNum: number,
    SubscriberNum: string,
    ExceptionInfo: string,
    StatusCode: string,
    StatusMessage: string,
    GroupNum: string
}

export type Plans = Plan[];

export const DEFAULT_PLAN: Plan = {
    id: 0,
    CarrierCode: "",
    DisplayPlanName: "",
    IsActive: false,
    PersonCode: "",
    PlanName: "",
    PlanNum: 0,
    SubscriberNum: "",
    ExceptionInfo: "",
    StatusCode: "",
    StatusMessage: "",
    GroupNum: ""
}