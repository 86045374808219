export type Formulary = {
  FormularyId: string;
  FormularyDisplayName: string;
  FormularyName?: string;
  FormularyLinkId?: number,
  FormularySettingId?: number,
  FormularyWebId: string,
  LogText: string,
  StatusCode: string,
  StatusMessage: string,
  ExceptionInfo: string
}

export type Formularies = Formulary[];

export const DEFAULT_FORMULARY: Formulary = {
  FormularyId: "",
  FormularyDisplayName: "",
  FormularyWebId: "",
  LogText: "",
  StatusCode: "",
  StatusMessage: "",
  ExceptionInfo: ""
}