import { Theme, createTheme } from '@mui/material/styles'
// import colors from './colors'

// const shadowArray = [
//   'none',
//   '0px 4px 10px 0px rgba(0, 0, 0, 0.25)',
//   '0px 6px 10px -4px rgba(0, 0, 0, 0.15)',
//   '0px 5px 10px 0px rgba(0, 0, 0, 0.45)',
//   '0px 4px 10px rgba(0, 0, 0, 0.15)',
// ]

const theme: Theme = createTheme({
  palette: {
    primary: {
      main: '#4297CB'
    },
    secondary: {
      // main: '#953192'
      main: '#981d97'
    },
    background: {
      paper: '#F8F9FA'
    },
    text: {
      primary: '#373737',
      secondary: '#373737'
    }
  },
  typography: {
    allVariants: {
      fontFamily: "'Calibri', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      color: '#373737'
    },
  },
})

export default theme;

/**
 * 
  palette: {
    primary: {
      main: colors.gainsBoro,
      light: colors.gainsBoro,
    },
    secondary: {
      main: colors.gainsBoro,
      dark: colors.gainsBoro,
      light: colors.gainsBoro,
    },
    text: {
      secondary: colors.gainsBoro,
    },
  },
  shadows: Array(25)
    .fill('none')
    .map((e, i) => shadowArray[i] || e) as Shadows,
  typography: {
    fontFamily: 'Roboto',
    fontWeightBold: 700,
    fontWeightMedium: 600,
    fontWeightRegular: 500,
    fontWeightLight: 400,
    body1: {
      fontSize: '1rem', // 16px
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.875rem', // 14px
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: '1rem', // 16px
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: '0.875rem', // 14px
      fontWeight: 600,
    },
    h6: {
      fontSize: '1.125rem', // 18px
      fontWeight: 500,
    },
    h5: {
      fontSize: '1.25rem', // 20px
      fontWeight: 600,
    },
    h4: {
      fontSize: '1.5rem', // 24px
      fontWeight: 400,
    },
    h3: {
      fontSize: '1.5rem', // 24px
      fontWeight: 500,
    },
    h2: {
      fontSize: '2rem', // 32px
      fontWeight: 400,
    },
    h1: {
      fontSize: '2.8125rem', // 45px
      fontWeight: 500,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          ':before': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          margin: '20px 0',
        },
        root: {
          padding: 0,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          color: colors.cyanBlue,
          textDecorationColor: colors.cyanBlue,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filledWarning: {
          fontWeight: 500,
          borderRadius: 0,
        },
      },
    },
  },
 */