import '../../styles/App.css';
import { AppBar, Box, Container, ThemeProvider, Toolbar } from '@mui/material';
import theme from '../../styles/materialTheme';
import Footer from '../common/Footer';
import initGA4 from '../../utils/google-analytics/ga4';
import logo from '../../assets/maxor_plus.svg';
import MontanaPharmacySearch from './MontanaPharmacySearch';
import { Plan, Plans } from '../../types/Plan';

export async function montanaLoader() {
  const urlPrefix = process.env.REACT_APP_EXT_SVC_BASE_URL;
  const carrier = 'TSTMT';
    
  const plans: Plans = await fetch(urlPrefix + '/GetMedAssistPlanSettingByCarrier', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      AppToken: process.env.REACT_APP_APP_TOKEN,
      CarrierCode: carrier
    })
  }).then((response) => response.json())
    .then((data) => {
      console.log(data);
      if(data.length === 1 && data.StatusMessage === 'NO RESULTS FOUND') {
        // TODO do something - error message ...?
      } else {
        let index = 1;
        const indexedPlans = data.map( (plan:Plan) => ({...plan, id:index++}) ); // adding index to each pharmacy
        return indexedPlans;
      }
  })
  .catch((err) => {
    console.log(err.message);
    return [];
  });

  return {
    plans: plans
  }
}

function MontanaApp() {
  initGA4(); // TODO figure out how to run init only once?

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" sx={{ bgcolor: theme.palette.background.paper }}>
            <Toolbar className='header' sx={{ borderBottom: 8, borderColor: theme.palette.secondary.main, pt:2, pb: 1 }}>
              <img src={logo} className="app-logo" alt="logo" />
              <div style={{'flexGrow': 1}}></div>
            </Toolbar>
          </AppBar>
        </Box>
        <Container maxWidth="xl" sx={{ minHeight: '85vh' }}>
          <MontanaPharmacySearch />
        </Container>
        <Footer />
      </ThemeProvider>
    </div>
  );
}
export default MontanaApp;
